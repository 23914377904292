import React from 'react';
import { useParams, Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Booking from './Booking/';

const Routes = () => {
  const location = useLocation();

  return (
    <React.Fragment>
      <Switch>
        <Route path="/requestpayment/:hotelId/:bookingId/:amount/:control" component={Booking} />
        <Route path="/confirm/:hotelId/:bookingId/:control" component={Booking} />
        <Route path="/cancel/:hotelId/:bookingId/:control" component={Booking} />
        <Route path="/cc/:hotelId/:bookingId/:control" component={props => <Booking {...props} isCC={true} />} />
        <Route path="/:hotelId/" component={Booking} />
        <Route path="/" component={Booking} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
