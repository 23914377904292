import React from 'react';
//import Checkbox from '@material-ui/core/Checkbox';
import { Chip, Box } from '@material-ui/core';
import CmtTimeLine from '../../@coremat/CmtTimeLine';
import parse from 'html-react-parser';
import CmtCard from '../../@coremat/CmtCard';
import CmtCardContent from '../../@coremat/CmtCard/CmtCardContent';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import GridContainer from '../../@jumbo/components/GridContainer';
import CmtTimeLineItem from '../../@coremat/CmtTimeLine/CmtTimeLineItem';
import { fade, IconButton, Tooltip } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CmtTimelineContent from '../../@coremat/CmtTimeLine/CmtTimelineContent';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtImage from '../../@coremat/CmtImage';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import IntlMessages from '../../@jumbo/utils/IntlMessages';
import NightsStayIcon from '@material-ui/icons/NightsStay';
import HotelIcon from '@material-ui/icons/Hotel';
import PeopleIcon from '@material-ui/icons/People';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { connect } from 'react-redux';
import AppContext from '../../@jumbo/components/contextProvider/AppContextProvider/AppContext';
import { currencies } from './data';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@material-ui/icons';
import { CheckBox } from '@material-ui/icons/CheckBox';
import ViewDetails from './ViewDetails';

const useStyles = theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(4),
    flex: 1,
  },
  setupPrice: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#ee6410',
  },
  currentPrice: {
    fontSize: '14px',
    fontWeight: 700,
    color: '#063e89',
  },
  card: {
    overflow: 'unset',
    marginBottom: theme.typography.pxToRem(16),
    marginRight: theme.typography.pxToRem(16),
  },
  cardContent: {
    position: 'relative',
    paddingBottom: 0,
    padding: 0,
  },
  card2: {
    overflow: 'unset',
    marginBottom: 0,
  },
  cardContent2: {
    position: 'relative',
    paddingBottom: 0,
    textAlign: 'center',
  },
  cardContentInner: {
    position: 'relative',
    paddingBottom: 0,
  },
  cardContentInner2: {
    position: 'relative',
    paddingBottom: 0,
    padding: 10,
  },
  cardContentInner3: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 0,
    color: '#ffffff',
    fontSize: 'x-large',
    height: '100%',
  },
  timelineView: {
    height: '100%',
    position: 'relative',
    '& .Cmt-timeline-item': {
      paddingLeft: 64,
      '&:before': {
        left: 20,
      },
      '&:first-child:before, &:last-child:before': {
        borderLeftStyle: 'solid',
      },
      '& .Cmt-timeline-badge': {
        transition: 'all 0.3s ease',
        transform: 'scale(1)',
      },
      '&:hover': {
        '& .Cmt-timeline-badge': {
          boxShadow: '0 4px 8px rgba(0,0,0,.45)',
          transform: 'scale(1.2)',
        },
      },
    },
    '& .Cmt-timeline-badge': {
      top: 0,
      width: 40,
      height: 40,
    },
  },
  titleRoot: {
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: 0.5,
    marginBottom: 4,
    cursor: 'pointer',
  },
  subTitleRoot: {
    fontSize: 12,
    letterSpacing: 0.4,
    color: theme.palette.text.secondary,
  },
  dateRoot: {
    fontSize: 12,
    letterSpacing: 0.4,
    color: theme.palette.text.disabled,
    textAlign: 'right',
  },
  currency: {
    width: '100%',
  },
});

class SolutionItem extends React.Component {
  constructor(props) {
    super(props);
    props = this.props;
    console.log(props.data);
    const mylen = 50;
    const myarray = new Array(mylen).fill(false);
    const myarray2 = new Array(mylen).fill({});
    var vid = -1;
    if (this.props.vid != null) vid = this.props.vid;
    //    if (vid != -1) alert(vid);
    this.state = {
      visible: myarray,
      resDetails: myarray2,
      selReservationId: '',
      selReservatonTitle: '',
      viewDetails: false,
      roomStatus: [],
      roomStatusColor: [],
      selReservationStatus: '',
      resDetailsVisible: false,
      selPrice: 0,
      myChecked: this.props.checked,
      vid: vid,
      addInfo: '',
    };
    console.log(myarray);
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getPrice = this.getPrice.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.showPrice = this.showPrice.bind(this);
  }

  handleCheck(value) {
    //    var value = this.state.myChecked;
    //    value = !value;
    if (value == true) {
      if (this.props.data.variants != null && this.state.vid == -1) {
        alert('Please select option.');
        value = false;
      }
      if (this.props.data.requires != null && this.props.oc[this.props.data.requires] != true) {
        alert('Please select feature #' + this.props.data.requires);
        value = false;
      }
      if (this.props.data.acceptText != null) {
        this.setState({ viewDetails: true });
      }
    } else {
    }

    this.setState({ myChecked: value });

    var price = 0,
      setupPrice = 0;
    if (value == true) {
      price = this.state.currentPrice;
      setupPrice = this.state.setupPrice;
    }
    this.props.setValue(this.props.data.id, price, setupPrice, value, this.state.vid);
  }

  /* handleSubmit(id, idres, title) {
      if (!this.props.showDetails) return;
      this.setState(prevState => {
        const newItems = [...prevState.visible];
        newItems[id] = !newItems[id];
        return { visible: newItems };
      });
      console.log(this.state.visible);
      const token = localStorage.getItem('token');
      const senddata = {
        Action: 'get_reservation_details',
        token: token,
        Version: 1,
        Data: {
          HotelId: '132',
          AllGuest: 1,
          ReservationId: idres,
        },
      };

      axios.post(`https://secure.webbookingpro.com/api/pass.php`, senddata).then(res => {
        this.setState(prevState => {
          const newItems = [...prevState.resDetails];
          newItems[id] = res.data; //.Data;
          return { resDetails: newItems };
        });
      });
    }
  */
  handleClick(event, idRes, title, status) {
    if (this.props.auth.authUser.clouduser == 1 && this.props.auth.authUser.features == 4) return;

    this.setState({ selReservationId: idRes });
    this.setState({ selReservationTitle: title });
    this.setState({ resDetailsVisible: true });
    this.setState({ selReservationStatus: status });
  }

  handleChange(vid) {
    this.setState({ vid: vid });
    var x = 0,
      y = 0,
      z;
    this.props.data.prices.forEach(
      function(item) {
        if (item.vid != null) {
          if (item.rc == this.props.rc && item.pc == this.props.pc && item.vid == vid) {
            x = item.value;
            y = item.setup;
            z = item.addInfo;
          }
        } else if (item.rc == this.props.rc && item.pc == this.props.pc) {
          x = item.value;
          y = item.setup;
          z = item.addInfo;
        }
      }.bind(this),
    );
    this.setState({ currentPrice: x, setupPrice: y, addInfo: z });
    if (this.state.myChecked == true) this.props.setValue(this.props.data.id, x, y, this.state.myChecked, vid);
    else this.props.setValue(this.props.data.id, 0, 0, false, vid);
  }

  getPrice() {
    var x = this.props.rc;

    return x;
  }

  componentDidMount() {
    var x = 0,
      y = 0,
      z;
    if (this.props.data.prices == null) {
      x = -1;
    }
    if (this.props.data.prices != null)
      this.props.data.prices.forEach(
        function(item) {
          if (item.vid != null) {
            if (item.rc == this.props.rc && item.pc == this.props.pc && item.vid == this.state.vid) {
              x = item.value;
              y = item.setup;
              z = item.addInfo;
            }
          } else if (item.rc == this.props.rc && item.pc == this.props.pc) {
            x = item.value;
            y = item.setup;
            z = item.addInfo;
          }
        }.bind(this),
      );
    this.setState({ currentPrice: x, setupPrice: y, addInfo: z });
    if (this.state.myChecked == true) this.props.setValue(this.props.data.id, x, y, this.state.myChecked, this.state.vid);
    else this.props.setValue(this.props.data.id, 0, 0, false, this.state.vid);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.rc !== this.props.rc) {
      var x = 0,
        y = 0,
        z;
      if (this.props.data.prices == null) {
        x = -1;
      }
      if (this.props.data.prices != null)
        this.props.data.prices.forEach(
          function(item) {
            if (item.vid != null) {
              if (item.rc == this.props.rc && item.pc == this.props.pc && item.vid == this.state.vid) {
                x = item.value;
                y = item.setup;
                z = item.addInfo;
              }
            } else if (item.rc == this.props.rc && item.pc == this.props.pc) {
              x = item.value;
              y = item.setup;
              z = item.addInfo;
            }
          }.bind(this),
        );
      this.setState({ currentPrice: x, setupPrice: y, addInfo: z });
      if (this.state.myChecked == true) this.props.setValue(this.props.data.id, x, this.stat.myChecked, this.state.vid);
      else this.props.setValue(this.props.data.id, 0, false, this.state.vid);
    }
  }

  showPrice(price) {
    var priceint = parseInt(price * this.props.ratio);
    /*    if (lang == 'en') {
      return price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }*/
    //if (lang == 'hr') {
    return (
      priceint.toLocaleString('hr-HR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' ' + this.props.currency
    );
    //}
  }

  render() {
    const { classes } = this.props;
    const locale = this.context.locale.locale;

    return (
      <>
        <CmtCard className={classes.card}>
          <CmtCardContent className={classes.cardContent}>
            <AppBar className={classes.appBar}>
              <Toolbar>
                <Typography variant="h6" className={classes.titleSolution}>
                  {this.props.data.title[locale]}
                </Typography>
              </Toolbar>
            </AppBar>

            <Box className={classes.cardContentInner2}>
              <Grid container alignContent="flex-end" justifyContent="flex-end">
                <Grid xs={6} item></Grid>
                <Grid xs={6} container alignContent="flex-end" justifyContent="flex-end" direction="row">
                  <Typography className={classes.currentPrice} component="label">
                    {this.state.currentPrice == 0 && this.state.setupPrice == 0 && <IntlMessages id="includedPrice" />}
                    {this.state.currentPrice == 0 && this.state.setupPrice > 0 && this.showPrice(this.state.setupPrice)}
                    {this.state.currentPrice > 0 && this.showPrice(this.state.currentPrice)}
                    {this.state.currentPrice == -1 && <IntlMessages id="individualPrice" />}
                  </Typography>
                </Grid>
                <Grid xs={12} container alignContent="flex-end" justifyContent="flex-end">
                  {this.state.setupPrice != 0 && this.state.currentPrice > 0 && (
                    <Typography className={classes.setupPrice} component="label">
                      <IntlMessages id="activationFee" /> {this.showPrice(this.state.setupPrice)}
                    </Typography>
                  )}
                </Grid>
                <Grid xs={12} container alignContent="flex-end" justifyContent="flex-end">
                  {this.state.addInfo != null && (
                    <Typography className={classes.setupPrice} component="label">
                      {this.state.addInfo[locale]}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>

            <Box className={classes.cardContentInner2}>{this.props.data.description[locale]}</Box>
            <Box className={classes.cardContentInner2}>
              <Grid container alignContent="flex-end" justifyContent="flex-end">
                <Grid xs={6} item>
                  {this.props.data.variants != null && (
                    <TextField
                      id="priceModel"
                      name="priceModel"
                      select
                      variant="outlined"
                      size="small"
                      margin="small"
                      className={classes.currency}
                      value={this.state.vid}
                      onChange={event => this.handleChange(event.target.value)}>
                      {this.props.data.variants.map((item, index) => (
                        <MenuItem value={item.vid}>{item.title[locale]}</MenuItem>
                      ))}
                    </TextField>
                  )}
                </Grid>
                <Grid xs={6} container alignContent="flex-end" justifyContent="flex-end" direction="row">
                  {this.state.myChecked == true && (
                    <IconButton onClick={() => this.handleCheck(false)}>
                      <CheckBoxOutlined fontSize="large" />
                    </IconButton>
                  )}
                  {!this.state.myChecked && (
                    <IconButton onClick={() => this.handleCheck(true)}>
                      <CheckBoxOutlineBlank fontSize="large" />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Box>
          </CmtCardContent>
        </CmtCard>
        {this.props.data.acceptText != null && (
          <ViewDetails
            open={this.state.viewDetails}
            anchorEl={this.state.myanchorEl}
            text={this.props.data.acceptText[locale]}
            setState={p => {
              this.setState(p);
            }}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

SolutionItem.contextType = AppContext;
export default withStyles(useStyles)(connect(mapStateToProps)(SolutionItem));
