import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

import AppContext from '../contextProvider/AppContextProvider/AppContext';
import globalStyles from '../../../theme/GlobalCss';
import { useDispatch, useSelector } from 'react-redux';
import { AuhMethods } from '../../../services/auth';
import { CurrentAuthMethod } from '../../constants/AppConstants';
import { LAYOUT_TYPES, THEME_TYPES } from '../../constants/ThemeOptions';
import VerticalDefault from './VerticalLayouts/VerticalDefault';
import VerticalMinimal from './VerticalLayouts/VerticalMinimal';
import MinimalNoHeader from './VerticalLayouts/MinimalNoHeader';
import ModernSideBar from './VerticalLayouts/ModernSidebar';
import HorizontalDefault from './HorizontalLayouts/HorizontalDefault';
import HorizontalDark from './HorizontalLayouts/HorizontalDark';
import HorizontalMinimal from './HorizontalLayouts/HorizontalMinimal';
import HorizontalTopMenu from './HorizontalLayouts/HorizontalTopMenu';
import { languageData } from './partials/LanguageSwitcher/data';

const useStyles = makeStyles(() => ({
  circularProgressRoot: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const AppLayout = ({ children }) => {
  const [showLayoutLoader, setLayoutLoader] = useState(true);
  const [userLanguage, setUserLanguage] = useState(false);
  const { layout } = useContext(AppContext);
  const { authUser, loadUser } = useSelector(({ auth }) => auth);
  const { locale, setLocale } = useContext(AppContext);
  const { themeType, setThemeType } = useContext(AppContext);
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  globalStyles();
  if (userLanguage == false) {
    var langId = '',
      darkTheme = 0;
    if (window.wbpSettings != null) {
      const settings = window.wbpSettings;
      if (settings.language != null) langId = settings.language;
      if (settings.darktheme != null) darkTheme = settings.darktheme;
    }

    const params = queryString.parse(window.location.search.substring(1));
    if (params != null && params.darktheme != null) {
      darkTheme = params.darktheme;
    }
    if (params != null && params.language != null) {
      langId = params.language;
    }

    if (langId != '') {
      if (langId == 'en') var item = languageData[0];
      if (langId == 'hr') var item = languageData[1];
      if (langId == 'de') var item = languageData[2];
      if (langId == 'it') var item = languageData[3];
      if (langId == 'sl') var item = languageData[4];
      if (langId == 'al') var item = languageData[6];
      if (langId == 'sr') var item = languageData[5];
      setLocale(item);
    }

    setUserLanguage(true);
    if (darkTheme == 1) setThemeType(THEME_TYPES.DARK);
    else setThemeType(THEME_TYPES.LIGHT);
  }

  useEffect(() => {
    dispatch(AuhMethods[CurrentAuthMethod].getAuthUser());
    setLayoutLoader(false);
  }, []);

  if (showLayoutLoader || !loadUser) {
    return (
      <div className={classes.circularProgressRoot}>
        <CircularProgress />
      </div>
    );
  }

  console.log(loadUser);

  return <div style={{ minHeight: '100vh', width: '100%', display: 'flex' }}>{children}</div>;
};

export default AppLayout;
