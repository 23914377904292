import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { setAuthUser, setForgetPassMailSent, updateLoadUser } from '../../../redux/actions/Auth';
import React, { useContext } from 'react';
import axios from './config';
import { flags, languageData } from '../../../@jumbo/components/AppLayout/partials/LanguageSwitcher/data.js';
import AppContext from '../../../@jumbo/components/contextProvider/AppContextProvider/AppContext.js';

const JWTAuth = {
  onRegister: ({ name, email, password }) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('', {
          email: email,
          password: password,
          name: name,
        })
        .then(({ data }) => {
          if (data.result) {
            localStorage.setItem('token', data.token.access_token);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.access_token;
            dispatch(fetchSuccess());
            dispatch(JWTAuth.getAuthUser(true, data.token.access_token));
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  onLogin: ({ email, password, hotelCode }) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        var myemail = email;
        var mypassword = password;
        var myhotelCode = hotelCode;
        if (myemail === undefined && mypassword == undefined && myhotelCode == undefined) {
          myemail = 'DEMO';
          mypassword = 'DEMO';
          myhotelCode = 'DEMO';
        }
        axios
          .post('', {
            Action: 'onLogin',
            email: myemail,
            password: mypassword,
            hotelCode: myhotelCode,
          })
          .then(({ data }) => {
            if (data.result) {
              localStorage.setItem('token', data.token.access_token);
              localStorage.setItem('apiKey', data.apiKey);
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.access_token;
              dispatch(fetchSuccess());
              dispatch(JWTAuth.getAuthUser(true, data.token.access_token));
              console.log('tu 1' + data.user.language);
            } else {
              dispatch(fetchError(data.error));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('', {
          Action: 'onLogout',
        })
        .then(({ data }) => {
          if (data.result) {
            dispatch(fetchSuccess());
            localStorage.removeItem('token');
            dispatch(setAuthUser(null));
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  onLogout2: () => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('', {
          Action: 'onLogout',
        })
        .then(({ data }) => {
          if (data.result) {
            dispatch(fetchSuccess());
            localStorage.removeItem('token');
            dispatch(setAuthUser(null));
            window.location = '/mobile';
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  getAuthUser: (loaded = false, token) => {
    return dispatch => {
      if (!token) {
        const token = localStorage.getItem('token');
        //        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

        //        console.log(getState());
      }
      const token = localStorage.getItem('token');

      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));
      //dispatch(fetchSuccess());
      dispatch(setAuthUser({}));
      /*axios
        .post('', { Action: 'GetHotelInfo', token: token })
        .then(({ data }) => {
          console.log(data);
          localStorage.setItem('hotel', JSON.stringify(data.hotel));

          if (data.result) {
            dispatch(fetchSuccess());

            //var item = languageData[0];
            //const { locale, setLocale } = useContext(AppContext);
            //console.log(AppContext);
            //console.log(locale.name);
            //  dispatch(setLocale(item));
            //const { pmsUrl, setPmsUrl } = useContext(AppContext);
            //data.user.langid = 0;

            console.log(data.hotel);
            dispatch(setAuthUser(data.hotel));
          } else {
            dispatch(updateLoadUser(true));
          }
        })
        .catch(function(error) {
          dispatch(updateLoadUser(true));
        });*/
    };
  },

  onForgotPassword: ({ email }) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('', {
          Action: 'onForgetPassword',
          email: email,
        })
        .then(({ data }) => {});

      setTimeout(() => {
        dispatch(setForgetPassMailSent(true));
        dispatch(fetchSuccess());
      }, 300);
    };
  },
  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default JWTAuth;
