import React from 'react';
import moment from 'moment';
import packageJson from '../../../../../package.json';
import preval from 'preval.macro';

const buildTimestamp = preval`module.exports = new Date().getTime();`;

class VersionNumber extends React.Component {
  getDateString = () => {
    const lastUpdateMoment = moment.unix(buildTimestamp / 1000);
    const formattedDate = lastUpdateMoment.format('YYYY.MM.DD.HHmm');

    return formattedDate;
  };

  render() {
    return <>{this.getDateString()}</>;
  }
}

VersionNumber.propTypes = {};

VersionNumber.defaultProps = {};

export default VersionNumber;
