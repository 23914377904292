export const languageData = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us',
  },
  {
    languageId: 'croatian',
    locale: 'hr',
    name: 'Hrvatski',
    icon: 'hr',
  },
  {
    languageId: 'albanian',
    locale: 'al',
    name: 'Shqip',
    icon: 'al',
  },
];

export const flags = {
  en: 'https://booking.webbookingpro.com/images/flag/icons8-great-britain-30.png',
  hr: 'https://booking.webbookingpro.com/images/flag/icons8-croatia-30.png',
  it: 'https://booking.webbookingpro.com/images/flag/icons8-italy-30.png',
  sl: 'https://booking.webbookingpro.com/images/flag/icons8-slovenia-30.png',
  sr: 'https://booking.webbookingpro.com/images/flag/icons8-serbia-30.png',
  es: 'https://booking.webbookingpro.com/images/flag/icons8-spain-30.png',
  de: 'https://booking.webbookingpro.com/images/flag/icons8-germany-30.png',
  al: 'https://booking.webbookingpro.com/images/flag/icons8-albanian-30.png',
  fr: 'https://booking.webbookingpro.com/images/flag/icons8-france-30.png',
  ru: 'https://booking.webbookingpro.com/images/flag/icons8-russian-federation-30.png',
};

/*export const general = {
  languages: 'en,hr',
  dateFormat: 'DD/MM/yyyy',
  maxAdults: 4,
  maxChildren: 4,
  defaultDays: 2,
  startDate: '',
};

export const properties = [
  {
    id: 18,
    destination: 'Krapinske Toplice',
    title: { en: 'Hotel Villa Magdaklena' },
    description: { en: 'Description' },
    additionalData: { en: 'add' },
    picture: 'https://secure.webbookingpro.com/images/hotels/hotel_18_kp9jddh0ruxxhubwrxpm.JPG',
    pricePerRoom: 0,
  },
];

export const rooms = [
  {
    id: 13,
    hotelId: 18,
    title: { en: 'Room1 English' },
    description: { en: 'Room1 Description' },
    pictures: { 0: 'https://secure.webbookingpro.com/images/rooms/18_icon_ywphng3nccn2olxbdcfw_thumb.jpg', 1: '', 2: '' },
    maxRooms: 12,
  },
];

export const services = [{ id: 11, hotelId: 18, title: { en: 'BB' } }];

export const packages = [{ hotelId: 18, id: 11, title: { en: 'Package1' }, description: { en: 'Opis' } }];

export const results = [
  { index: 0, roomId: 13, serviceId: 11, priceOrignal: 100, maxRooms: 2, priceFinal: 100, taxes: 10, policyId: 0 },
  { index: 1, roomId: 13, packageId: 11, priceOrignal: 200, maxRooms: 2, priceFinal: 200, taxes: 10, policyId: 0 },
];

export const extras = [
  { id: 100, hotelId: 18, price: 100, model: 1, title: { en: 'extra service en' } },
  { id: 101, hotelId: 18, price: 100, model: 1, title: { en: 'extra service en' } },
];

export const policies = [
  { id: 0, description: { en: 'Uvjeti en' } },
  { id: 1, description: { en: 'Non ref Uvjeti en' } },
];

export const payments = [
  { hotelId: 18, id: 'poa', paymentInfo: 'poa' },
  { hotelId: 18, id: 'online', paymentInfo: 'online' },
];*/

export const applicationCategories = [
  { id: 0, pc: 0, title: { en: 'Pro (usable on multiple workstations)', hr: 'Pro (više računala u mreži)' } },
  { id: 1, pc: 0, title: { en: 'Standard (usable on a single workstation)', hr: 'Standard (jedno računalo)' } },
  { id: 1, pc: 1, title: { en: 'Standard (usable on a single workstation)', hr: 'Standard (jedno računalo)' } },
];

export const discounts = [
  {
    value: 12,
    label: '-10%',
    factor: 0.9,
  },
];
/*export const currencies = [
  { id: 0, title: 'EUR', ratio: 1 },
  { id: 1, title: 'HRK', ratio: 7.53 },
];*/

export const languageCategories = [
  { id: 0, title: 'Hrvatski' },
  { id: 1, title: 'English' },
];

export const propertyCategories = [
  { id: 0, title: { en: 'Hotel', hr: 'Hotel' } },
  { id: 1, title: { en: 'Apartments', hr: 'Apartmani' } },
];

export const roomCategories = [
  { id: 0, pc: 0, title: '1-10' },
  { id: 1, pc: 0, title: '11-25' },
  { id: 2, pc: 0, title: '26-50' },
  { id: 3, pc: 0, title: '51-100' },
  { id: 4, pc: 0, title: '101-200' },
  { id: 5, pc: 0, title: '201-300' },
  { id: 6, pc: 0, title: '301-' },
  { id: 0, pc: 1, title: '1-5' },
  { id: 1, pc: 1, title: '6-10' },
  { id: 2, pc: 1, title: '11-20' },
];

export const solutionsCategories = [
  { id: 0, title: { en: 'Basic', hr: 'Osnovno' } },
  { id: 1, title: { en: 'Cloud/Mobile Solutions', hr: 'Cloud/Mobilni pristup' } },
  { id: 2, title: { en: 'Channel Manager', hr: 'Channel Manager' } },
  { id: 3, title: { en: 'Additional Extensions', hr: 'Dodatne poveznice' } },
];

export const solutionsData = [
  {
    id: 0,
    categoryId: 0,
    title: { en: 'Simple module', hr: 'Osnovni modul' },
    description: { en: 'Simple moule description', hr: 'Osnovni modul opis' },
    prices: [
      { rc: 0, pc: 0, value: 100, setup: 0 },
      { rc: 1, pc: 0, value: 200, setup: 0 },
      { rc: 2, pc: 0, value: 300, setup: 0 },
      { rc: 3, pc: 0, value: 400, setup: 0 },
    ],
  },
  {
    id: 1,
    categoryId: 2,
    title: { en: 'Simple module with Accept', hr: 'Osnovni modul s potvrdom' },
    description: { en: 'Simple module with Accept description', hr: 'Osnovni modul s potvrdom opis' },
    acceptText: {
      en: 'Tekst or html that user has to accept before checking.',
      hr: 'tekst ili html kojeg korisnik mora potvrditi prije označavanja',
    },
    prices: [
      { rc: 0, pc: 0, value: 50, setup: 10 },
      { rc: 1, pc: 0, value: 100, setup: 10 },
      { rc: 2, pc: 0, value: 200, setup: 10 },
      { rc: 3, pc: 0, value: 300, setup: 10 },
    ],
  },
  {
    id: 2,
    categoryId: 3,
    title: { en: 'Simple module with Individual price', hr: 'Osnovni modul s individualnom cijenom' },
    description: { en: 'Simple module with Individual price description', hr: 'Osnovni modul s individualnom cijenom' },
    acceptText: {
      en: 'Tekst or html that user has to accept before checking.',
      hr: 'tekst ili html kojeg korisnik mora potvrditi prije označavanja',
    },
  },
  {
    id: 3,
    categoryId: 1,
    title: { en: 'Modul with options ', hr: 'Modul s opcijama' },
    description: { en: 'Modul with options description', hr: 'Modul s opcijama opis' },
    variants: [
      { vid: 0, title: { en: 'Web Access ', hr: 'Web Access' } },
      { vid: 1, title: { en: 'Mobile Access ', hr: 'Mobile Access' } },
      { vid: 2, title: { en: 'Web+Mobile Access ', hr: 'Web+Mobile Access' } },
    ],
    prices: [
      { vid: 0, rc: 0, pc: 0, value: 50, setup: 10 },
      { vid: 0, rc: 1, pc: 0, value: 100, setup: 10 },
      { vid: 0, rc: 2, pc: 0, value: 200, setup: 10 },
      { vid: 0, rc: 3, pc: 0, value: 300, setup: 10 },
      { vid: 1, rc: 0, pc: 0, value: 50, setup: 10 },
      { vid: 1, rc: 1, pc: 0, value: 100, setup: 10 },
      { vid: 1, rc: 2, pc: 0, value: 200, setup: 10 },
      { vid: 1, rc: 3, pc: 0, value: 300, setup: 10 },
      { vid: 2, rc: 0, pc: 0, value: 100, setup: 10 },
      { vid: 2, rc: 1, pc: 0, value: 200, setup: 10 },
      { vid: 2, rc: 2, pc: 0, value: 300, setup: 10 },
      { vid: 2, rc: 3, pc: 0, value: 400, setup: 10 },
    ],
  },
  {
    id: 4,
    categoryId: 1,
    title: { en: 'Modul that requires another', hr: 'Modul koji zahtjeva drugi' },
    description: { en: 'Hotel Mobile Analytics description', hr: 'Hotel Mobile Analytics opis' },
    requires: 3,
    prices: [
      { rc: 0, pc: 0, value: 50, setup: 10 },
      { rc: 1, pc: 0, value: 100, setup: 10 },
      { rc: 2, pc: 0, value: 200, setup: 10 },
      { rc: 3, pc: 0, value: 300, setup: 10 },
    ],
  },
  {
    id: 5,
    categoryId: 2,
    title: { en: 'On the spot training', hr: 'Edukacija na terenu' },
    description: { en: 'On the spot training description', hr: 'Edukacija na terenu opis' },
    prices: [
      {
        rc: 0,
        pc: 0,
        value: 0,
        setup: 1000,
        addInfo: { en: 'The price does not include travel costs.', hr: 'Cijena ne uključuje troškove boravka.' },
      },
      {
        rc: 1,
        pc: 0,
        value: 0,
        setup: 1000,
        addInfo: { en: 'The price does not include travel costs.', hr: 'Cijena ne uključuje troškove boravka.' },
      },
      {
        rc: 2,
        pc: 0,
        value: 0,
        setup: 1000,
        addInfo: { en: 'The price does not include travel costs.', hr: 'Cijena ne uključuje troškove boravka.' },
      },
      {
        rc: 3,
        pc: 0,
        value: 0,
        setup: 1000,
        addInfo: { en: 'The price does not include travel costs.', hr: 'Cijena ne uključuje troškove boravka.' },
      },
    ],
  },
];
