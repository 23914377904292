export const capitalizeFLetter = string => {
  return string[0].toUpperCase() + string.slice(1);
};

export const isValidEmail = value => {
  return value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(value);
};

export const idGenerator = () => {
  return Math.floor(Math.random() * 100000);
};

export const linkify = inputText => {
  var replacedText, replacePattern1, replacePattern2, replacePattern3;

  //URLs starting with http://, https://, or ftp://
  replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/gim;
  replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^\\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

  //Change email addresses to mailto:: links.
  replacePattern3 = /(([a-zA-Z0-9\-_.])+@[a-zA-Z0-9\\-]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

  return replacedText;
};

export const geValidUrl = (url, ubSecureUrl = false) => {
  if (!url.match(/^[a-zA-Z]+:\/\//)) {
    if (ubSecureUrl) {
      return 'http://' + url;
    }
    return 'https://' + url;
  }

  return url;
};

const dayinWeekEn = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const dayinWeekDe = ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'];
const dayinWeekSl = ['Ned', 'Pon', 'Tor', 'Sre', 'Čet', 'Pet', 'Sob'];
const dayinWeekIt = ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'];
const dayinWeekHr = ['Ned', 'Pon', 'Uto', 'Sri', 'Čet', 'Pet', 'Sub'];
const monthEn = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const monthDe = ['Jan', 'Feb', 'Mar', 'Apr', 'Kan', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'];
const monthIt = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
const monthSl = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Avg', 'Sep', 'Okt', 'Nov', 'Dec'];
const monthHr = ['Sij', 'Velj', 'Ožu', 'Tra', 'Svi', 'Lip', 'Srp', 'Kol', 'Ruj', 'Lis', 'Stu', 'Pro'];

export const getMyDate1 = (mydate, lang) => {
  var d = new Date(mydate);
  var d1 = '',
    d2 = '';

  if (lang == 'hr') d1 = dayinWeekHr[d.getDay()];
  else d1 = dayinWeekEn[d.getDay()];
  if (lang == 'hr') d2 = monthHr[d.getMonth()];
  else d2 = monthEn[d.getMonth()];
  return '<b>' + d1 + ' ' + d.getDate() + '</b><br/>' + d2; // + ' ' + d.getFullYear();
};

export const getMyDate2 = (mydate, lang) => {
  //var d = new Date(mydate + 'T00:00:00.000+01:00');

  //var f = new Date(from[2], from[1] - 1, from[0])
  if (String(mydate).length == 10) {
    var from = mydate.split('-');
    var d = new Date(from[0], from[1] - 1, from[2], 12, 0, 0, 0);
  } else var d = new Date(mydate + 'T00:00:00.000+01:00');

  if (d == null || d == undefined) return '';
  var d1 = '',
    d2 = '';
  if (lang == 'hr') d1 = dayinWeekHr[d.getDay()];
  else if (lang == 'de') d1 = dayinWeekDe[d.getDay()];
  else if (lang == 'sl') d1 = dayinWeekSl[d.getDay()];
  else if (lang == 'it') d1 = dayinWeekIt[d.getDay()];
  else d1 = dayinWeekEn[d.getDay()];
  if (lang == 'hr') d2 = monthHr[d.getMonth()];
  else if (lang == 'de') d2 = monthDe[d.getMonth()];
  else if (lang == 'sl') d2 = monthSl[d.getMonth()];
  else if (lang == 'it') d2 = monthIt[d.getMonth()];
  else d2 = monthEn[d.getMonth()];

  return '' + d1 + ', ' + d.getDate() + ' ' + d2 + ' ' + d.getFullYear();
};

export const getMyDate3 = (mydate, lang) => {
  if (mydate == null) return '';
  var d = new Date(mydate);
  var d1 = '',
    d2 = '';

  if (lang == 'hr') d1 = dayinWeekHr[d.getDay()];
  else d1 = dayinWeekEn[d.getDay()];
  if (lang == 'hr') d2 = monthHr[d.getMonth()];
  else d2 = monthEn[d.getMonth()];

  return (
    '' +
    d1 +
    ', ' +
    d.getDate() +
    ' ' +
    d2 +
    ' ' +
    d.getFullYear() +
    ' ' +
    ('0' + d.getHours()).slice(-2) +
    ':' +
    ('0' + d.getMinutes()).slice(-2)
  );
};

export const getMyDate = prop => {
  var md = new Date(prop);
  var dd = String(md.getDate()).padStart(2, '0');
  var mm = String(md.getMonth() + 1).padStart(2, '0');
  var yyyy = md.getFullYear();
  return yyyy + '-' + mm + '-' + dd;
};

export const getMyTime3 = prop => {
  var md = new Date(prop);
  var dd = String(md.getHours()).padStart(2, '0');
  var mm = String(md.getMinutes()).padStart(2, '0');
  return dd + ':' + mm;
};

export const getMyTime = (time1, time2) => {
  var d1 = new Date(time1);
  var d2 = new Date(time2);

  var s1 = String(d1.getHours()).padStart(2, '0') + ':' + String(d1.getMinutes()).padStart(2, '0');
  var s2 = String(d2.getHours()).padStart(2, '0') + ':' + String(d2.getMinutes()).padStart(2, '0');
  return s1 + ' - ' + s2;
};

export const getMyTime2 = (time1, time2) => {
  var d1 = new Date(time1);
  var d2 = new Date(time2);
  var diff = (d1.getTime() - d2.getTime()) / 1000;
  diff /= 60 * 60;
  return Math.abs(Math.round(diff));
};

export const getMyDate4 = (mydate, lang) => {
  var md = new Date(mydate);
  var dd = String(md.getDate());
  var mm = String(md.getMonth() + 1);
  var yyyy = md.getFullYear();
  return dd + '.' + mm + '.' + yyyy;
};

export const formatPrice = (price, lang, x = 2) => {
  var p1 = price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  var p2 = price.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  if (p1 == p2 + '.00') x = 0;

  if (lang == 'hr') {
    return price.toLocaleString('hr-HR', { minimumFractionDigits: x, maximumFractionDigits: x });
  }
  if (lang == 'de') {
    return price.toLocaleString('de-DE', { minimumFractionDigits: x, maximumFractionDigits: x });
  }
  if (lang == 'it') {
    return price.toLocaleString('it-IT', { minimumFractionDigits: x, maximumFractionDigits: x });
  }
  if (lang == 'sl') {
    return price.toLocaleString('sl-SL', { minimumFractionDigits: x, maximumFractionDigits: x });
  }
  if (lang == 'sr') {
    return price.toLocaleString('sr-SR', { minimumFractionDigits: x, maximumFractionDigits: x });
  }
  return price.toLocaleString('en-US', { minimumFractionDigits: x, maximumFractionDigits: x });
};
