import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Switch } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';

import configureStore, { history } from './redux/store';
import AppWrapper from './@jumbo/components/AppWrapper';
import AppContextProvider from './@jumbo/components/contextProvider/AppContextProvider';
import Routes from './routes';

import { library } from '@fortawesome/fontawesome-svg-core';
//import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faRadio,
  faBanSmoking,
  faWater,
  faUmbrellaBeach,
  faPaw,
  faBellConcierge,
  faSnowflake,
  faTv,
  faPersonSwimming,
  faKitchenSet,
  faSquareParking,
  faBabyCarriage,
  faUtensils,
  faWifi,
  faHandshake,
  faDumbbell,
  faSpa,
  faHotTubPerson,
  faElevator,
  faCoffee,
  faMartiniGlassCitrus,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faPaw,
  faRadio,
  faBanSmoking,
  faWater,
  faUmbrellaBeach,
  faBellConcierge,
  faUtensils,
  faSnowflake,
  faTv,
  faPersonSwimming,
  faKitchenSet,
  faSquareParking,
  faBabyCarriage,
  faWifi,
  faHandshake,
  faDumbbell,
  faSpa,
  faHotTubPerson,
  faElevator,
  faCoffee,
  faMartiniGlassCitrus,
);

require('./App.css');
export const store = configureStore();

const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <AppContextProvider>
        <AppWrapper>
          <Switch>
            <Routes />
          </Switch>
        </AppWrapper>
      </AppContextProvider>
    </ConnectedRouter>
  </Provider>
);

export default App;
