import React from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';

import CmtVertical from '../../../../../@coremat/CmtNavigation/Vertical';
import IntlMessages from '../../../../utils/IntlMessages';
import {
  DateRange,
  NewReleases,
  BarChart,
  PostAdd,
  Schedule,
  FormatListBulleted,
  EventAvailable,
  AccountBalanceWallet,
  FreeBreakfast,
  LocalAtm,
  Money,
  AddBox,
  CreditCard,
  DoneAll,
  ArrowDownward,
  ArrowUpward,
  PieChart,
  AssignmentTurnedIn,
  ShoppingCart,
  Cast,
  Room,
} from '@material-ui/icons';
import BusinessIcon from '@material-ui/icons/Business';
import PeopleIcon from '@material-ui/icons/People';
import ReceiptIcon from '@material-ui/icons/Receipt';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import CalendarViewDay from '@material-ui/icons/CalendarViewDay';

import { useSelector } from 'react-redux';
import HotelIcon from '@material-ui/icons/Hotel';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';

const useStyles = makeStyles(() => ({
  perfectScrollbarSidebar: {
    height: '100%',
    transition: 'all 0.3s ease',
    '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
      //  height: 'calc(100% - 167px)',
    },
    '.Cmt-modernLayout &': {
      //  height: 'calc(100% - 72px)',
    },
    '.Cmt-miniLayout &': {
      //  height: 'calc(100% - 91px)',
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      //    height: 'calc(100% - 167px)',
    },
  },
}));

const SideBar = () => {
  const classes = useStyles();
  const navigationMenus = [];
  const { authUser } = useSelector(({ auth }) => auth);
  var userRole = -1,
    bookingengine = 0,
    vcenabled = 0,
    taskenabled = 0,
    expired = 0,
    features = 0;
  var cloudUser = 0;
  if (authUser != null) userRole = authUser.role;
  if (authUser != null) bookingengine = authUser.bookingengine;
  if (authUser != null) vcenabled = authUser.vcenabled;
  if (authUser != null) taskenabled = authUser.apptasks;
  if (authUser != null) cloudUser = authUser.clouduser;
  if (authUser != null) expired = authUser.expired;
  if (authUser != null) features = authUser.features;

  if (cloudUser == 0 && expired == 1)
    navigationMenus.push({
      name: <IntlMessages id={'pages.orderNow'} />,
      type: 'item',
      icon: <ShoppingCart />,
      link: '/pages/upgrade',
    });

  if (cloudUser == 1 && (features & 1) != 0)
    navigationMenus.push({
      name: <IntlMessages id={'pages.cockpit'} />,
      type: 'item',
      icon: <BarChart />,
      link: '/pages/cockpit',
    });
  if ([10].includes(userRole) && cloudUser == 0)
    navigationMenus.push({
      name: <IntlMessages id={'pages.cockpit'} />,
      type: 'item',
      icon: <BarChart />,
      link: '/pages/cockpit-wbp',
    });

  if ((cloudUser == 1 && (features & 2) != 0) || (cloudUser == 0 && [10].includes(userRole)))
    navigationMenus.push({
      name: <IntlMessages id={'pages.timeline'} />,
      type: 'item',
      icon: <Schedule />,
      link: '/pages/timeline',
    });

  if (cloudUser == 1 && (features & 2) != 0 /* || (cloudUser==0 && [10].includes(userRole))*/)
    navigationMenus.push({
      name: <IntlMessages id={'pages.calendar'} />,
      type: 'item',
      icon: <CalendarViewDay />,
      link: '/pages/calendar',
    });

  if ((cloudUser == 1 && (features & 2) != 0) || (cloudUser == 0 && [10].includes(userRole)))
    navigationMenus.push({
      name: <IntlMessages id={'pages.quickReservation'} />,
      type: 'item',
      icon: <EventAvailable />,
      link: '/pages/quickreservation',
    });

  if (cloudUser == 0 && [10].includes(userRole)) {
    navigationMenus.push({
      name: <IntlMessages id={'pages.bookings'} />,
      type: 'item',
      icon: <DateRange />,
      link: '/pages/bookings',
    });
  }
  if (cloudUser == 1 && (features & 2) != 0) {
    navigationMenus.push({
      name: <IntlMessages id={'pages.bookings'} />,
      type: 'item',
      icon: <DateRange />,
      link: '/pages/bookingsm',
    });
  }

  if ([10].includes(userRole) && cloudUser == 0) {
    navigationMenus.push({
      name: <IntlMessages id={'pages.invoices'} />,
      type: 'item',
      icon: <ReceiptIcon />,
      link: '/pages/invoices',
    });
  }

  if ([10].includes(userRole) && cloudUser == 0) {
    var link = '/pages/statistics';
    if (expired == 1) link = '/pages/upgrade';
    navigationMenus.push({
      name: <IntlMessages id={'pages.statistics'} />,
      type: 'item',
      icon: <PieChart />,
      link: link,
    });
  }

  if (cloudUser == 1 && (features & 1) != 0) {
    var link = '/pages/statisticsm';
    if (expired == 1) link = '/pages/upgrade';
    navigationMenus.push({
      name: <IntlMessages id={'pages.statistics'} />,
      type: 'item',
      icon: <PieChart />,
      link: link,
    });
  }

  if (bookingengine == 1 || (cloudUser == 1 && (features & 2) != 0) || (cloudUser == 1 && (features & 4) != 0)) {
    if ([5, 10].includes(userRole))
      navigationMenus.push({
        name: <IntlMessages id={'pages.arrivals'} />,
        type: 'item',
        icon: <ArrowDownward />,
        link: '/pages/arrivals',
      });
    if ([5, 10].includes(userRole))
      navigationMenus.push({
        name: <IntlMessages id={'pages.stay'} />,
        type: 'item',
        icon: <Room />,
        link: '/pages/stay',
      });

    if ([5, 10].includes(userRole))
      navigationMenus.push({
        name: <IntlMessages id={'pages.departures'} />,
        type: 'item',
        icon: <ArrowUpward />,
        link: '/pages/departures',
      });
  }
  if (taskenabled == 1) {
    var link = '/admin/mod_tasks';
    if (expired == 1) link = '/pages/upgrade';

    navigationMenus.push({
      name: 'Tasks',
      type: 'item',
      icon: <AssignmentTurnedIn />,
      link: link,
    });
  }
  if (vcenabled == 1) {
    var link = '/admin/mod_vc';
    if (expired == 1) link = '/pages/upgrade';
    navigationMenus.push({
      name: <IntlMessages id="application.virtualConcierge" />,
      type: 'item',
      icon: <RecordVoiceOverIcon />,
      link: link,
    });
  }
  if (cloudUser == 1) {
    navigationMenus.push({
      name: <IntlMessages id={'pages.logout'} />,
      type: 'item',
      icon: <ExitToAppIcon />,
      link: '/pages/logout',
    });
  }

  if ([10].includes(userRole) && cloudUser == 0) {
    navigationMenus.push({
      name: <IntlMessages id="pages.hotelsAndRooms" />,
      type: 'item',
      icon: <HotelIcon />,
      link: '/admin/hotels_info',
    });

    var link = '/pages/upgrade';
    if (expired == 1) {
      navigationMenus.push(
        {
          name: <IntlMessages id="pages.accounts" />,
          type: 'section',
          children: [
            {
              name: <IntlMessages id="pages.customers" />,
              type: 'item',
              icon: <PeopleIcon />,
              link: link,
            },
            {
              name: <IntlMessages id="pages.travelAgencies" />,
              type: 'item',
              icon: <BusinessIcon />,
              link: link,
            },
          ],
        },
        {
          name: <IntlMessages id={'pages.promotions'} />,
          type: 'section',
          children: [
            {
              name: <IntlMessages id={'pages.promotionsDiscounts'} />,
              type: 'item',
              icon: <MoneyOffIcon />,
              link: link,
            },
            {
              name: <IntlMessages id="pages.coupons" />,
              type: 'item',
              icon: <AccountBalanceWalletIcon />,
              link: link,
            },
          ],
        },
        {
          name: <IntlMessages id="pages.properties" />,
          type: 'section',
          children: [
            {
              name: <IntlMessages id="pages.mealPlans" />,
              type: 'item',
              icon: <FreeBreakfast />,
              link: link,
            },
            {
              name: <IntlMessages id="pages.currencies" />,
              type: 'item',
              icon: <Money />,
              link: link,
            },
            {
              name: <IntlMessages id="pages.ratePlans" />,
              type: 'item',
              icon: <LocalAtm />,
              link: link,
            },
            {
              name: <IntlMessages id="pages.packages" />,
              type: 'item',
              icon: <DoneAll />,
              link: link,
            },
            {
              name: <IntlMessages id="pages.extras" />,
              type: 'item',
              icon: <AddBox />,
              link: link,
            },
            {
              name: <IntlMessages id="pages.taxes" />,
              type: 'item',
              icon: <Money />,
              link: link,
            },
            {
              name: <IntlMessages id="pages.paymentGateways" />,
              type: 'item',
              icon: <CreditCard />,
              link: link,
            },
          ],
        },
      );
    }
    if (expired == 0) {
      navigationMenus.push({
        name: <IntlMessages id="pages.channelManager" />,
        type: 'item',
        icon: <Cast />,
        link: '/admin/mod_channel_manager_hotels',
      });

      navigationMenus.push(
        {
          name: <IntlMessages id="pages.accounts" />,
          type: 'section',
          children: [
            {
              name: <IntlMessages id="pages.customers" />,
              type: 'item',
              icon: <PeopleIcon />,
              link: '/admin/mod_customers_management',
            },
            {
              name: <IntlMessages id="pages.travelAgencies" />,
              type: 'item',
              icon: <BusinessIcon />,
              link: '/admin/mod_customers_agencies',
            },
          ],
        },
        {
          name: <IntlMessages id={'pages.promotions'} />,
          type: 'section',
          children: [
            {
              name: <IntlMessages id={'pages.promotionsDiscounts'} />,
              type: 'item',
              icon: <MoneyOffIcon />,
              link: '/admin/mod_booking_campaigns',
            },
            {
              name: <IntlMessages id="pages.coupons" />,
              type: 'item',
              icon: <AccountBalanceWalletIcon />,
              link: '/admin/mod_booking_coupons',
            },
          ],
        },
        {
          name: <IntlMessages id="pages.properties" />,
          type: 'section',
          children: [
            {
              name: <IntlMessages id="pages.mealPlans" />,
              type: 'item',
              icon: <FreeBreakfast />,
              link: '/admin/mod_booking_meal_plans',
            },
            {
              name: <IntlMessages id="pages.currencies" />,
              type: 'item',
              icon: <Money />,
              link: '/admin/mod_booking_currencies',
            },
            {
              name: <IntlMessages id="pages.ratePlans" />,
              type: 'item',
              icon: <LocalAtm />,
              link: '/admin/mod_booking_pricelists',
            },
            {
              name: <IntlMessages id="pages.packages" />,
              type: 'item',
              icon: <DoneAll />,
              link: '/admin/mod_booking_packages',
            },
            {
              name: <IntlMessages id="pages.extras" />,
              type: 'item',
              icon: <AddBox />,
              link: '/admin/mod_booking_extras',
            },
            {
              name: <IntlMessages id="pages.taxes" />,
              type: 'item',
              icon: <Money />,
              link: '/admin/mod_booking_taxes',
            },
            {
              name: <IntlMessages id="pages.paymentGateways" />,
              type: 'item',
              icon: <CreditCard />,
              link: '/admin/mod_booking_hotel_payment_gateways',
            },
          ],
        },
      );
    }
  }
  /*if ([10].includes(userRole))
    navigationMenus.push({
      name: 'Bookings',
      type: 'item',
      icon: <FormatListBulleted />,
      link: '/pages/bookings',
    });*/

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <CmtVertical menuItems={navigationMenus} />
    </PerfectScrollbar>
  );
};

export default SideBar;
