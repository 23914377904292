import React, { useContext, useState } from 'react';
import { IconButton, MenuItem, MenuList, Paper, Popover, useTheme } from '@material-ui/core';
import AppContext from '../../../contextProvider/AppContextProvider/AppContext';
import LanguageItem from './LanguageItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtCard from '../../../../../@coremat/CmtCard';
import CmtCardHeader from '../../../../../@coremat/CmtCard/CmtCardHeader';
import CmtList from '../../../../../@coremat/CmtList';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CmtImage from '../../../../../@coremat/CmtImage';
import { flags, languageData } from './data';
import axios from 'axios';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { AuhMethods } from '../../../../../services/auth';
import { CurrentAuthMethod } from '../../../../constants/AppConstants';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SettingsIcon from '@material-ui/icons/Settings';

const useStyles = makeStyles(() => ({
  cardRoot: {
    '& .Cmt-header-root': {
      paddingTop: 14,
      paddingBottom: 14,
    },
  },
  perfectScrollbarLanguage: {
    height: 324,
  },
  menuItemRoot: {
    paddingTop: 0,
    paddingBottom: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  popoverRoot: {
    '& .MuiPopover-paper': {
      width: 205,
    },
  },
}));

const LanguageSwitcher = () => {
  const classes = useStyles();
  const { locale, setLocale } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const theme = useTheme();
  const dispatch = useDispatch();
  let history = useHistory();
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'language' : undefined;

  const handlePopoverClose = () => {
    handleClose();

    history.push('/pages/profile');
  };

  const onLogoutClick = () => {
    handlePopoverClose();
    dispatch(AuhMethods[CurrentAuthMethod].onLogout());
  };

  const switchLanguage = item => {
    console.log(item);
    console.log(item.locale);
    const token = localStorage.getItem('token');
    const senddata = {
      Action: 'changeUserSetting',
      token: token,
      Data: {
        language: item.locale,
      },
    };

    axios.post('https://secure.webbookingpro.com/api/pms.php', senddata).then(res => {
      setLocale(item);
      handleClose();
    });
  };

  return (
    <React.Fragment>
      <IconButton size="small" onClick={handleClick} data-tut="reactour__localization">
        <SettingsIcon />
      </IconButton>

      <Popover
        className={classes.popoverRoot}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <CmtCard className={classes.cardRoot}>
          <CmtCardHeader
            title="Settings"
            separator={{
              color: theme.palette.borderColor.dark,
              borderWidth: 1,
              borderStyle: 'solid',
            }}
          />
          <MenuList>
            <MenuItem onClick={handlePopoverClose}>
              <PersonIcon />
              <div className="ml-2">Profile</div>
            </MenuItem>

            <MenuItem onClick={onLogoutClick}>
              <ExitToAppIcon />
              <div className="ml-2">Logout</div>
            </MenuItem>
          </MenuList>
          <CmtCardHeader
            title="Language"
            separator={{
              color: theme.palette.borderColor.dark,
              borderWidth: 1,
              borderStyle: 'solid',
            }}
          />
          <PerfectScrollbar className={classes.perfectScrollbarLanguage}>
            <CmtList
              data={languageData}
              renderRow={(item, index) => <LanguageItem key={index} language={item} onClick={switchLanguage} />}
            />
          </PerfectScrollbar>
        </CmtCard>
      </Popover>
    </React.Fragment>
  );
};

export default LanguageSwitcher;
