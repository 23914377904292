import enLang from './entries/en-US';
import zhLang from './entries/zh-Hans-CN';
import arLang from './entries/ar_SA';
import itLang from './entries/it_IT';
import esLang from './entries/es_ES';
import frLang from './entries/fr_FR';
import hrLang from './entries/hr_HR';
import deLang from './entries/de_DE';
import slLang from './entries/sl_SI';
import srLang from './entries/sr_RS';
import alLang from './entries/al_AL';

const AppLocale = {
  en: enLang,
  hr: hrLang,
  zh: zhLang,
  ar: arLang,
  it: itLang,
  es: esLang,
  fr: frLang,
  de: deLang,
  sr: srLang,
  sl: slLang,
  al: alLang,
};

export default AppLocale;
