import React, { useContext } from 'react';
import FooterLogo from './FooterLogo';
import { useHistory } from 'react-router-dom';
import { Box, makeStyles } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import AppContext from '../../contextProvider/AppContextProvider/AppContext';
import { THEME_TYPES } from '../../../constants/ThemeOptions';
import { useSelector } from 'react-redux';
import VersionNumber from './VersionNumber';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { ArrowDownward, ArrowUpward, BarChart, DateRange, EventAvailable, Room, Schedule } from '@material-ui/icons';
import IntlMessages from '../../../utils/IntlMessages';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.default,
  },
  btnRoot: {
    [theme.breakpoints.down('xs')]: {
      padding: '6px 12px',
      fontSize: 11,
    },
  },
}));

const Footer = props => {
  const classes = useStyles();
  const { themeType } = useContext(AppContext);
  const date = new Date();
  var userRole = -1;
  const { authUser } = useSelector(({ auth }) => auth);
  if (authUser != null) userRole = authUser.role;
  const { version } = require('../../../../../package.json');
  var myfooter = ''; //'version: ' + version;
  if (authUser != null) myfooter = myfooter + ', features: ' + authUser.features;
  const history = useHistory();
  var features = 0;
  if (authUser != null) features = authUser.features;

  const change = newValue => {
    if (authUser != null && authUser.clouduser == 1) {
      if ((features & 2) != 0) {
        if (newValue == 0) history.push('/pages/cockpit');
        if (newValue == 1) history.push('/pages/timeline');
        if (newValue == 2) history.push('/pages/quickreservation');
      } else {
        if (newValue == 0) history.push('/pages/arrivals');
        if (newValue == 1) history.push('/pages/departures');
        if (newValue == 2) history.push('/pages/stay');
      }
    }
    if (authUser != null && authUser.clouduser == 0) {
      if (newValue == 0) history.push('/pages/cockpit-wbp');
      if (newValue == 1) history.push('/pages/bookings');
      if (newValue == 2) history.push('/pages/quickreservation');
    }
  };

  if (authUser != null && authUser.clouduser == 1 && (features & 2) != 0)
    return (
      <BottomNavigation
        showLabels
        className={classes.root}
        onChange={(event, newValue) => {
          change(newValue);
        }}>
        <BottomNavigationAction label=<IntlMessages id={'pages.cockpit'} /> icon={<BarChart />} />
        <BottomNavigationAction label=<IntlMessages id={'pages.timeline'} /> icon={<Schedule />} />
        <BottomNavigationAction label=<IntlMessages id={'pages.quickReservation'} /> icon={<EventAvailable />} />
      </BottomNavigation>
    );

  if (authUser != null && authUser.clouduser == 1 && (features & 4) != 0)
    return (
      <BottomNavigation
        showLabels
        className={classes.root}
        onChange={(event, newValue) => {
          change(newValue);
        }}>
        <BottomNavigationAction label=<IntlMessages id={'pages.arrivals'} /> icon={<ArrowDownward />} />
        <BottomNavigationAction label=<IntlMessages id={'pages.departures'} /> icon={<ArrowUpward />} />
        <BottomNavigationAction label=<IntlMessages id={'pages.stay'} /> icon={<Room />} />
      </BottomNavigation>
    );

  if (authUser != null && authUser.clouduser == 0)
    return (
      <BottomNavigation
        showLabels
        className={classes.root}
        onChange={(event, newValue) => {
          change(newValue);
        }}>
        <BottomNavigationAction label=<IntlMessages id={'pages.cockpit'} /> icon={<BarChart />} />
        <BottomNavigationAction label=<IntlMessages id={'pages.bookings'} /> icon={<DateRange />} />
        <BottomNavigationAction label=<IntlMessages id={'pages.quickReservation'} /> icon={<EventAvailable />} />
      </BottomNavigation>
    );

  return <></>;
};

export default Footer;
/*
<Box className={classes.root} {...props}>
      <Box display="flex" alignItems="center">
        {/*<Hidden xsDown>    <FooterLogo mr={5} color={themeType === THEME_TYPES.DARK ? 'white' : ''} /></Hidden>/}
<Box fontSize={{ xs: 12, sm: 14 }} component="p" color="text.disabled">
  version: <VersionNumber /> {myfooter}
</Box>
</Box>
</Box>
 */
