import React, { useContext } from 'react';
import SidebarToggleHandler from '../../../../../@coremat/CmtLayouts/Vertical/SidebarToggleHandler';
import Toolbar from '@material-ui/core/Toolbar';
import { Box, fade, IconButton, InputBase, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import LanguageSwitcher from '../LanguageSwitcher';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SearchIcon from '@material-ui/icons/Search';
import AppsMenu from './AppsMenu';
import FeedbackIcon from '@material-ui/icons/Feedback';
import Typography from '@material-ui/core/Typography';
import HeaderNotifications from './HeaderNotifications';
import HeaderMessages from './HeaderMessages';
import Hidden from '@material-ui/core/Hidden';
import Logo from '../Logo';
import SearchPopover from '../SearchPopover';
import HelpIcon from '@material-ui/icons/Help';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useSelector } from 'react-redux';
import AppContext from '../../../contextProvider/AppContextProvider/AppContext';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingLeft: 16,
    paddingRight: 16,
    minHeight: 64,
    [theme.breakpoints.up('md')]: {
      minHeight: 72,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  searchRoot: {
    position: 'relative',
    width: 260,
    [theme.breakpoints.up('md')]: {
      width: 350,
    },
    '& .MuiSvgIcon-root': {
      position: 'absolute',
      left: 18,
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 1,
    },
    '& .MuiInputBase-root': {
      width: '100%',
    },
    '& .MuiInputBase-input': {
      height: 48,
      borderRadius: 30,
      backgroundColor: fade(theme.palette.common.dark, 0.38),
      color: fade(theme.palette.common.white, 0.7),
      boxSizing: 'border-box',
      padding: '5px 15px 5px 50px',
      transition: 'all 0.3s ease',
      '&:focus': {
        backgroundColor: fade(theme.palette.common.dark, 0.58),
        color: fade(theme.palette.common.white, 0.7),
      },
    },
  },
  langRoot: {
    borderLeft: `solid 1px ${fade(theme.palette.common.dark, 0.15)}`,
    minHeight: 72,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 8,
    paddingRight: 8,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      minHeight: 64,
    },
  },
  iconBtn: {
    color: fade(theme.palette.common.white, 0.38),
    '&:hover, &:focus': {
      color: theme.palette.common.white,
    },
  },
}));

const Header = () => {
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const { currentPage, setCurrentPage } = useContext(AppContext);
  return (
    <Toolbar className={classes.root}>
      <SidebarToggleHandler edge="start" color="inherit" aria-label="menu" />
      {authUser != null && authUser.clouduser == 0 && <Logo ml={2} color="white" />}
      <Box flex={1}>{authUser != null && authUser.clouduser == 1 && <Typography>{currentPage}</Typography>}</Box>
      {/*
      <Hidden smDown>
        <Box pr={3} className={classes.searchRoot}>
          <InputBase placeholder={'Search here...'} inputProps={{ 'aria-label': 'search' }} />
          <SearchIcon />
        </Box>
      </Hidden>
          */}
      {authUser != null && authUser.clouduser == 0 && <AppsMenu />}
      {authUser != null && authUser.clouduser == 0 && (
        <Tooltip title="FeedBack">
          <IconButton component={Link} to="/pages/feedback">
            <FeedbackIcon />
          </IconButton>
        </Tooltip>
      )}
      {authUser != null && authUser.clouduser == 0 && (
        <Tooltip title="Help">
          <IconButton component={Link} to="/pages/help">
            <HelpIcon />
          </IconButton>
        </Tooltip>
      )}
      {authUser != null && authUser.clouduser == 0 && (
        <Box className={classes.langRoot}>
          <LanguageSwitcher />
        </Box>
      )}
      {authUser != null && authUser.clouduser == 1 && (
        <Tooltip title="Refresh">
          <IconButton
            onClick={() => {
              window.location.reload(false);
            }}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

export default Header;
