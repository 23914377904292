import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import { Box } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import CmtImage from '../../../../@coremat/CmtImage';
import { useSelector } from 'react-redux';

const Logo = ({ color, ...props }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  var logoUrl = '';
  var logoSymbolUrl = '';

  if (authUser != null) {
    if (authUser.clouduser == 0) {
      logoUrl = color === 'white' ? '/images/logo-wbp-white.png' : '/images/logo-wbp.png';
      logoSymbolUrl = color === 'white' ? '/images/logo-wbp-white-symbol.png' : '/images/logo-wbp-symbol.png';
    } else {
      logoUrl = color === 'white' ? '/images/logo-milenij-white.png' : '/images/logo-milenij.png';
      logoSymbolUrl = color === 'white' ? '/images/logo-milenij-white-symbol.png' : '/images/logo-milenij-symbol.png';
    }
  }
  return (
    <Box className="pointer" {...props}>
      <Hidden xsDown>
        <NavLink to="/">
          <CmtImage src={logoUrl} alt="logo" />
        </NavLink>
      </Hidden>
      <Hidden smUp>
        <NavLink to="/">
          <CmtImage src={logoSymbolUrl} alt="logo" />
        </NavLink>
      </Hidden>
    </Box>
  );
};

export default Logo;
