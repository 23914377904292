import React, { Component } from 'react';
import parse from 'html-react-parser';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { fade, Modal, Typography, useTheme } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Popover from '@material-ui/core/Popover';

import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Calendar from './index';
import Box from '@material-ui/core/Box';
import CmtGridView from '../../@coremat/CmtGridView';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IntlMessages from '../../@jumbo/utils/IntlMessages';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const DialogTitle = withStyles(useStyles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);

const useStyles = theme => ({
  boxTop: {
    paddingTop: theme.spacing(5),
  },
  appBar: {
    position: 'relative',
  },
  myButton: {
    margin: theme.spacing(2),
    minWidth: 200,
  },
  root: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(5),
  },
  myButton: {
    margin: theme.spacing(2),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  cardRoot: {
    '& .Cmt-header-root': {
      paddingTop: 10,
      paddingBottom: 0,
    },
  },
  tabsList: {
    position: 'relative',
    minHeight: 60,
    '& .MuiTabs-indicator': {
      backgroundColor: fade(theme.palette.primary.main, 0.8),
    },
  },
  tabItem: {
    maxWidth: 'none',
    minWidth: 10,
    minHeight: 60,
    padding: '5px 10px',
    textTransform: 'capitalize',
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
  },
  columnRoot: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.5px',
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class ViewDetails extends Component {
  //
  //const [open, setOpen] = React.useState(this.props.open);
  constructor(props) {
    super(props);
    this.props = props;
    this.handleClose = this.handleClose.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    // this.handleClick = this.handleClick.bind(this);
    this.state = { data: {}, rows: [], ccName: '', ccNumber: '', ccExpire: '', ccType: '' };
  }
  handleClickOpen() {
    //  setOpen(true);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.resId !== this.props.resId) {
    }
  }

  handleClose() {
    this.props.setState({ viewDetails: false });
    //  setOpen(false);
  }

  handleRemove() {
    this.props.setState({ viewDetails: false });
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Modal
          open={this.props.open}
          onClose={this.handleClose}
          anchorEl={this.props.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}>
          <Box sx={style}>
            {this.props.text}
            <Button variant="contained" color="primary" className={classes.myButton} onClick={() => this.handleClose()}>
              <IntlMessages id="decline" />
            </Button>
            <Button variant="contained" color="primary" className={classes.myButton} onClick={() => this.handleRemove()}>
              <IntlMessages id="accept" />
            </Button>
          </Box>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

export default withStyles(useStyles)(connect(mapStateToProps)(ViewDetails));
