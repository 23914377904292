/*import Firebase from './firebase';
 */
import JWTAuth from './jwt';
//import BasicAuth from './Basic';

export const AuhMethods = {
  /*firebase: Firebase,*/
  jwtAuth: JWTAuth /*,
  basic: BasicAuth,,*/,
};
